'use strict';
// Declare app level module which depends on views, and components
angular.module('DMPortalAdminApp', [
    'ngRoute',
    'ngSanitize',
    'LocalStorageModule',
    'ui.bootstrap',
    'ui.tree',
    'ui.tinymce',
    'ngFileUpload',
    'DMPortalAdminApp.DMUtilsService',
    'DMPortalAdminApp.DMUserAdminService',
    'DMPortalAdminApp.DMPortalSettingService',
    'DMPortalAdminApp.DMAdminObjectsService',
    'DMPortalAdminApp.DMCompanyAdminService',
    'DMPortalAdminApp.DMAdminYouTubeService',
    'DMPortalAdminApp.DMPageContentService',
    /*
     'DMPortalAdminApp.DMCompanyService',
     'DMPortalAdminApp.DMObjectsService',
    'DMAdminCompanyCtrl',
    'DMUserAdminCtrl',
    'DMPortalSettingCtrl',
    'DMAdminVideoCtrl',
    'DMAdminVideoChannelCtrl',
    'DMAdminPageContentCtrl',
    'DMSettingsDirective',
    'DMCompanyDirective',
    'DMUserDirective',
    */
    'DMPortalAdminApp.home',
    'DMPortalAdminApp.customContent',
    'DMPortalAdminApp.portalSettings',
    'DMPortalAdminApp.users',
    'DMPortalAdminApp.videoChannels',
    'DMPortalAdminApp.videos',

    'DMPortalAdminApp.settingsDirective',
    'DMPortalAdminApp.companyDirective'

]).
config(['$locationProvider', '$routeProvider', 'localStorageServiceProvider', function($locationProvider, $routeProvider, localStorageServiceProvider) {
    $locationProvider.hashPrefix('!');

    $routeProvider.otherwise({redirectTo: '/home'});

    localStorageServiceProvider
        .setPrefix("DM")
        .setStorageCookie(0, '/', false); // This is session cookie

}]).
run(['$rootScope', '$http', '$location', 'localStorageService', function($rootScope, $http, $location, localStorageService) {

    // keep user logged in after page refresh
    if (localStorageService.get("jwt")) {
        $http.defaults.headers.common.Authorization = 'Bearer ' + localStorageService.get("jwt");
    }

    // redirect to login page if not logged in and trying to access a restricted page
    $rootScope.$on('$locationChangeStart', function (event, next, current) {

        var publicPages = ['/login'];
        var restrictedPage = publicPages.indexOf($location.path()) === -1;

        console.log("$locationChangeStart to " + restrictedPage);

        if (restrictedPage && !localStorageService.get("jwt")) {
            $location.path('/login');
        }
    });
}]);
