'use strict';

angular.module('DMPortalAdminApp.DMUserAdminService', [])
    .factory("UserAdminService", ['UtilsService', function(UtilsService) {

        var apiUrl = "/portal/api/admin/";
        var userApiUrl = apiUrl + "user";
        var roleApiUrl = apiUrl + "role";

        function getLetters() {

            var url = userApiUrl + "/letters";
            return UtilsService.sendMessage('GET', url);
        }

        function getUsersByLetter(letter) {

            var url = userApiUrl + "/letters/" + letter;
            return UtilsService.sendMessage('GET', url);
        }

        function getUsersByParentId(parentId) {

            var url = userApiUrl + "/children/" + parentId;
            return UtilsService.sendMessage('GET', url);
        }

        function getAllUsers() {

            return UtilsService.sendMessage('GET', userApiUrl);
        }

        function getRoles() {

            return $http.get(roleApiUrl, {cache: true});
        }

        function searchUsers(searchCriterias) {

            var url = userApiUrl;

            console.log(searchCriterias);
            if (searchCriterias.keyword) {
                url = url + "/search/" + searchCriterias.keyword;

                if (searchCriterias.userType)
                    url = url + "/" + searchCriterias.userType.code;

            } else if (searchCriterias.userType) {
                url = url + "/type/" + searchCriterias.userType.code;
            }

            return UtilsService.sendMessage('GET', url);

        }

        function getUser(id) {

            var url = userApiUrl + "/" + id;
            return UtilsService.sendMessage('GET', url);
        }


        function saveUser(user) {

            var saveUrl = userApiUrl;
            return UtilsService.sendMessage('POST', saveUrl, user);

        }

        function saveUserAuth(auth) {

            var saveUrl = userApiUrl + "/auth";
            return UtilsService.sendMessage('POST', saveUrl, auth);

        }

        function deleteUserAuth(authId) {

            var saveUrl = userApiUrl + "/auth/" + authId;
            return UtilsService.sendMessage('DELETE', saveUrl);

        }

        function saveUserRole(userApiUrl, roleId) {

            var saveUrl = apiUrl + "/save";
            var prms = {user_id: userId, role_id:roleId};
            return UtilsService.sendMessage('POST', saveUrl, user);

        }

        function deleteUserRole(userApiUrl, roleId) {

            var deleteUrl = apiUrl + "/delete";
            var prms = {user_id: userId, role_id:roleId};

            return UtilsService.sendMessage('DELETE', deleteUrl, user);

        }

        return {
            getLetters: function() {
                return getLetters();
            },

            getUsersByLetter:function(letter) {
                return getUsersByLetter(letter);
            },

            getUsersByParentId:function(parentId) {
                return getUsersByParentId(parentId);
            },

            getUsers: function() {
                return getAllUsers();
            },

            getUser: function(id) {
                return getUser(id);
            },

            searchUsers: function(searchCriterias) {
                return searchUsers(searchCriterias);
            },

            saveUser: function(user) {
                return saveUser(user);
            },

            getRoles: function() {
                return getRoles();
            },

            saveUserRole: function(userId, roleId) {
                return saveUserRole(userId, roleId);
            },

            deleteUserRole: function(userId, roleId) {
                return deleteUserRole(userId, roleId);
            },

            saveUserAuth:function(auth) {
                return saveUserAuth(auth);
            },

            deleteUserAuth:function(authId) {
                return deleteUserAuth(authId);
            }


        };
    }]);

