'use strict';

angular.module('DMPortalAdminApp.DMAdminYouTubeService', [])
    .factory("AdminYouTubeService", ['UtilsService', function(UtilsService) {

        var objectsAdminApiUrl = "/portal/api/admin/youtube";

        function getVideo(videoId) {

            var url = objectsAdminApiUrl + "/" + videoId;
            return UtilsService.sendMessage('GET', url);

        }

        function getChannel(channelId) {

            var url = objectsAdminApiUrl + "/channel" + channelId;
            return UtilsService.sendMessage('GET', url);

        }

        function getChannelVideos(channelId) {

            var url = objectsAdminApiUrl + "/channel/videos/" + channelId;
            return UtilsService.sendMessage('GET', url);

        }

        function createChannel(channelId) {

            var url = objectsAdminApiUrl + "/channel/all/" + channelId;
            return UtilsService.sendMessage('POST', url);

        }

        function deleteChannel(channelId) {

            var url = objectsAdminApiUrl + "/channel/" + channelId;
            return UtilsService.sendMessage('DELETE', url);

        }


        return {
            getVideo: function(videoId) {
                return getVideo(videoId);
            },

            getChannel: function(channelId) {
                return getChannel(channelId);
            },

            getChannelVideos: function(channelId) {
                return getChannelVideos(channelId);
            },

            createChannel: function(channelId) {
                return createChannel(channelId);
            },

            deleteChannel: function(channelId) {
                return deleteChannel(channelId);
            }
        }
    }])
