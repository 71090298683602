'use strict';

angular.module('DMPortalAdminApp.DMPortalSettingService', [])
    .factory("PortalSettingService", ['UtilsService', function(UtilsService) {

        var settingApiUrl = "/portal/api/settings";
        var settingValuesApiUrl = "/portal/api/settings/values";
        var privilegesApiUrl = "/portal/api/applications";

        var databases = null;
        var services = null;

        function getLanguage(language) {

            var l = "";
            if (language)
                l = language;

            return l;
        }

        function getAllSettings() {

            return UtilsService.sendMessage('GET', settingApiUrl);
        }

        function getSetting(id, lang) {


            return UtilsService.sendMessage('GET', settingApiUrl  + "/" + id + "/" + getLanguage(lang));
        }

        function getSettingValues(setting, lang) {

            var l = "";
            if (lang)
                l = lang;

            return UtilsService.sendMessage('GET', settingValuesApiUrl+"/" + setting + "/" + getLanguage(lang));
        }

        function getSettingValuesByName(name, lang) {

            var l = "";
            if (lang)
                l = lang;

            return UtilsService.sendMessage('GET', settingValuesApiUrl+"/" + name + "/" + getLanguage(lang));
        }

        function searchSettingValues(name, keyword, lang) {

            var l = "";
            if (lang)
                l = lang;

            return UtilsService.sendMessage('GET', settingApiUrl+"/search/" + "/" + name + "/" + keyword + "/" + getLanguage(lang) );
        }

        function getSettingValue(id) {

            return UtilsService.sendMessage('GET', settingValuesApiUrl + "/value/" + id);
        }


        function saveSetting(setting) {

            var saveUrl = settingApiUrl + "/save";
            return UtilsService.sendMessage('POST', saveUrl, setting)

        }

        function deleteSetting(settingId) {

            var deleteUrl = settingApiUrl + "/delete/" + settingId;
            return UtilsService.sendMessage('DELETE', deleteUrl)

        }


        function saveSettingValue(settingValue) {

            settingValue.lang = settingValue.lang.code;
            var saveUrl = settingValuesApiUrl + "/save";
            return UtilsService.sendMessage('POST', saveUrl, settingValue)

        }

        function deleteSettingValue(id) {

            var deleteUrl = settingValuesApiUrl + "/delete/" + id;
            return UtilsService.sendMessage('DELETE', deleteUrl)

        }

        function getPrivileges() {
            return UtilsService.sendMessage('GET', privilegesApiUrl)
        }

        function getPrivilegesByGroup(group) {

            return UtilsService.sendMessage('GET', privilegesApiUrl + "/group/" + group)
        }

        function getCategoryHierarchy(categoryId, id, lang) {
            return UtilsService.sendMessage('GET', settingApiUrl + "/category/" + categoryId + "/" + id + "/" + lang)
        }

        function getCategory(categoryId, id, lang){
            return UtilsService.sendMessage('GET', settingApiUrl + "/categoryItem/" + categoryId + "/" + id + "/" + lang)
        }

        return {

            getSettings: function() {
                return getAllSettings();
            },

            getSetting: function(id, lang) {
                return getSetting(id, lang);
            },

            getSettingValues: function(settingId, lang) {
                return getSettingValues(settingId, lang);
            },

            getSettingValuesByName: function(name, lang) {
                return getSettingValuesByName(name, lang);
            },

            searchSettingValues: function(name,keyword, lang) {
                return searchSettingValues(name, keyword, lang);
            },

            getSettingValue: function(id) {
                return getSettingValue(id);
            },


            saveSetting: function(setting) {
                return saveSetting(setting);
            },

            deleteSetting: function(id) {
                return deleteSetting(id);
            },


            saveSettingValue: function(settingValue) {
                return saveSettingValue(settingValue);
            },

            deleteSettingValue: function(id) {
                return deleteSettingValue(id);
            },

            getPrivileges: function() {
                return getPrivileges();
            },

            getPrivilegesByGroup: function(group) {
                return getPrivilegesByGroup(group);
            },

            getCategoryHierarchy: function(categoryId, id, lang) {
                return getCategoryHierarchy(categoryId, id, lang);
            },

            getCategory: function(categoryId, id, lang) {
                return getCategory(categoryId, id, lang);
            }


        };
    }]);

