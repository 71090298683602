'use strict';

angular.module('DMPortalAdminApp.DMUtilsService', [])
    .factory("UtilsService", ['$http', function($http) {

        //var HOST = "http://beta.dentalmammoth.net";
        var HOST = "http://localhost:8080";
        function sendMessage(method, url, prms, page) {

            if (page)
                url = url + "?page=" + page;

            return $http({
                method: method,
                url: HOST + url,
                data: prms,
                headers: {'Content-Type': 'application/json'}
            });

        }

        function sendFormData(url, prms) {

            var formParams = $.param(prms);
            return $http({
                method: "POST",
                url: HOST + url,
                data: formParams,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            });
        }

        function sendMultipartFormData(url, formData) {

            console.log(formData);

            //var formParams = $.param(prms);
            return $http({
                method: "POST",
                url: HOST +  url,
                data: formData,
                headers: {'Content-Type':undefined } //'multipart/form-data'
            });

        }


        function showError(msg) {
            var errorMessage = $('#errorModal').find("#errorMessage");
            errorMessage.html("");
            errorMessage.html(msg);
            $('#errorModal').modal('show');
        }

        function getErrorMessage(response) {

            var status = response.status;
            var statusText = response.statusText;
            var errorMessage = response.data.message;
            var msg = status;

            if (statusText)
                msg = msg + " " + statusText;

            if (errorMessage)
                msg = msg + ": " + errorMessage;

            return msg;

        }

        function uplaodFile(formData){

            var url = HOST + '/portal/api/customContent/upload';
            return sendMultipartFormData(url, formData);

        }
        return {
            sendMessage: function(method, url, prms, page) {
                return sendMessage(method, url, prms, page);
            },

            sendFormData: function(url, prms) {
                return sendFormData(url, prms);
            },


            showErrorDlg: function(msg){
                return showError(msg);
            },

            getErrorMessage: function(response) {
                return getErrorMessage(response);
            },

            uploadFile: function(formData) {
                return uplaodFile(formData);
            }

        }

    }])

