'use strict';

angular.module('DMPortalAdminApp.DMPageContentService', [])
    .factory("PageContentService", ['UtilsService', function(UtilsService) {

        var apiUrl = "/portal/api/customContent";

        function getCustomContent() {

            var url = apiUrl;
            return UtilsService.sendMessage('GET', url);
        }

        function getCustomContents() {

            var url = apiUrl + "/contents";
            return UtilsService.sendMessage('GET', url);
        }

        function getCustomContentById(id) {

            var url = apiUrl + "/" + id;
            return UtilsService.sendMessage('GET', url);

        }

        function saveCustomContent(content) {

            var url = apiUrl;
            return UtilsService.sendMessage('POST', url, content);

        }

        function deleteCustomContent(id) {

            var url = apiUrl + "/" + id;
            return UtilsService.sendMessage('DELETE', url);

        }

        function approveCustomContent(id) {

            var url = apiUrl + "/" + id + "/approve";
            return UtilsService.sendMessage('PUT', url);

        }

        function unapproveCustomContent(id) {

            var url = apiUrl + "/" + id + "/unapprove";
            return UtilsService.sendMessage('PUT', url);

        }

        return {
            getCustomContent: function() {
                return getCustomContent();
            },

            getCustomContents: function() {
                return getCustomContents();
            },

            getCustomContentById: function(id) {
                return getCustomContentById(id);
            },

            saveCustomContent: function(content) {
                return saveCustomContent(content);
            },

            deleteCustomContent: function(id) {
                return deleteCustomContent(id);
            },

            approveCustomContent: function(id) {
                return approveCustomContent(id);
            },

            unapproveCustomContent: function(id) {
                return unapproveCustomContent(id);
            },



        };
    }]);

