'use strict';

angular.module('DMPortalAdminApp.DMAdminObjectsService', [])
    .factory("AdminObjectsService", ['UtilsService', function(UtilsService) {

        var objectsAdminApiUrl = "/portal/api/admin/objects";

        function getObjectsByKeyword(type, keyword) {

            var url = objectsAdminApiUrl + "/search/" + type + "/" + keyword;
            return UtilsService.sendMessage('GET', url);

        }

        function letters(type) {

            var url = objectsAdminApiUrl + "/" + type + "/letters";
            return UtilsService.sendMessage('GET', url);

        }

        function getObjectsByLetters(type, letter) {

            var url = objectsAdminApiUrl + "/" + type + "/letters/" + letter;
            return UtilsService.sendMessage('GET', url);

        }

        function getObjectById(id, status) {

            var url = objectsAdminApiUrl + "/object/" + id;
            return UtilsService.sendMessage('GET', url);

        }

        function getObjectsByType(type, status) {

            var url = objectsAdminApiUrl + "/" + type + "/" + status;
            return UtilsService.sendMessage('GET', url);

        }

        function getObjectByParentId(parentId, type) {

            var url = objectsAdminApiUrl + "/object/parent/" + parentId + "/" + type;
            return UtilsService.sendMessage('GET', url);

        }

        function getObjectByObjectId(ObjectId) {

            var url = objectsAdminApiUrl + "/objectId/" + ObjectId;
            return UtilsService.sendMessage('GET', url);

        }

        function saveObject(object) {
            var url = objectsAdminApiUrl
            return UtilsService.sendMessage('POST', url, object);
        }

        function deleteObject(object) {
            console.log(object);
            var url = objectsAdminApiUrl + "/" + object.objectType + "/" + object.id;
            return UtilsService.sendMessage('DELETE', url, object);
        }

        function saveObjectAttribute(objectAttribute) {

            var url = objectsAdminApiUrl + "/attribute/save";
            return UtilsService.sendMessage('POST', url, objectAttribute);
        }

        function saveObjectAttributes(objectAttributes) {

            var url = objectsAdminApiUrl + "/attributes/save";
            return UtilsService.sendMessage('POST', url, objectAttributes);
        }

        function approveObject(object) {
            var url = objectsAdminApiUrl + "/approve/" + object.id;
            return UtilsService.sendMessage('PUT', url, object);
        }

        function unApproveObject(object) {
            var url = objectsAdminApiUrl + "/unapprove/" + object.id;
            return UtilsService.sendMessage('PUT', url, object);
        }

        function archiveObject(object) {
            var url = objectsAdminApiUrl + "/archive/" + object.id;
            return UtilsService.sendMessage('PUT', url, object);
        }

        return {
            getObjectsByKeyword: function(type, keyword) {
                return getObjectsByKeyword(type, keyword);
            },

            letters: function(type) {
                return letters(type);
            },

            getObjectsByLetters: function(type, letter) {
                return getObjectsByLetters(type, letter);
            },

            getObjectById: function(id, status) {
                return getObjectById(id, status);
            },

            getObjectsByType: function(type, status) {
                return getObjectsByType(type, status);
            },

            getObjectByObjectId: function(id) {
                return getObjectByObjectId(id);
            },

            getObjectByParentId:function(parentId, type) {
                return getObjectByParentId(parentId, type);
            },

            saveObject:function(object) {
                return saveObject(object);
            },

            deleteObject:function(object) {
                return deleteObject(object);
            },

            saveObjectAttribute:function(objcetAttribute) {
                return saveObjectAttribute(objcetAttribute);
            },

            saveObjectAttributes:function(objcetAttributes) {
                return saveObjectAttributes(objcetAttributes);
            },

            approveObject:function(object) {
                return approveObject(object);
            },

            unApproveObject:function(object) {
                return unApproveObject(object);
            },

            archiveObject:function(object) {
                return archiveObject(object);
            }



        }
    }])
