 'use strict';

 angular.module('DMPortalAdminApp.DMCompanyAdminService', [])
     .factory("CompanyAdminService", ['UtilsService', function(UtilsService) {

         var companyAdminApiUrl = "/portal/api/admin/companies";

         function getCompanies() {

             var url = companyAdminApiUrl;
             return UtilsService.sendMessage('GET', url);

         }

         function letters(type) {

             var url = companyAdminApiUrl + "/letters";
             return UtilsService.sendMessage('GET', url);

         }

         function getCompaniesByLetter(letter) {

             var url = companyAdminApiUrl  + "/letter/" + letter;
             return UtilsService.sendMessage('GET', url);

         }

         function search(keyword) {

             var url = companyAdminApiUrl + "/search/" + keyword;
             return UtilsService.sendMessage('GET', url);
         }

         function getCompanyById(id) {

             var url = companyAdminApiUrl + "/" + id;
             return UtilsService.sendMessage('GET', url);

         }

         function saveCompany(company) {

             var url = companyAdminApiUrl;
             return UtilsService.sendMessage('POST', url, company);
         }

         function deleteCompany(id) {

             var url = companyAdminApiUrl + "/" + id;
             return UtilsService.sendMessage('DELETE', url);
         }

         function saveCompanyInfo(companyinfo) {

             var url = companyApiUrl + "/info/save";
             return UtilsService.sendMessage('POST', url, companyinfo);
         }

         function deleteCompanyInfo(id) {

             var url = companyApiUrl + "/delete/info/" + id;
             return UtilsService.sendMessage('DELETE', url);
         }


         return {
             getCompanies:function() {
                 return getCompanies();
             },

             letters: function(type) {
                 return letters(type);
             },

             getCompaniesByLetter: function(letter) {
                 return getCompaniesByLetter(letter);
             },

             search: function(keyword) {
                 return search(keyword);
             },

             getCompanyById: function(id) {
                 return getCompanyById(id);
             },

             saveCompany:function(company) {
                 return saveCompany(company);
             },

             deleteCompany:function(id) {
                 return deleteCompany(id);
             },

             saveCompanyInfo:function(companyInfo) {
                 return saveCompanyInfo(companyInfo);
             },

             deleteCompanyInfo:function(id) {
                 return deleteCompanyInfo(id);
             }

         }
     }])
